import { render, staticRenderFns } from "./WrongBook.vue?vue&type=template&id=b7a762ca&scoped=true&"
import script from "./WrongBook.vue?vue&type=script&lang=js&"
export * from "./WrongBook.vue?vue&type=script&lang=js&"
import style0 from "./WrongBook.vue?vue&type=style&index=0&id=b7a762ca&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7a762ca",
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QTd});
