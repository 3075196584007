<template>
    <div class="wrongBook">
        <div v-if="$route.path == '/wrongBook'">
            <div class="page-section total-wrong">
                {{$t('home.TotalWrongAnswers')}}：
                <span class="wrong-link">{{ totalWrong }}</span>
            </div>
            <div class="page-section">
                <div class="title">{{$t('home.ResultByCategory')}}</div>
                <div v-if="$i18n.locale == 'zh'">
                    <q-table
                            :data="WrongTypedata"
                            :columns="columns"
                            separator="cell"
                            @row-click="rowClick"
                            flat
                            bordered
                            :rows-per-page-options="[20]"
                    >
                        <template v-slot:body-cell-protein="props">
                            <td  class="q-td text-right">
                                <span

                                        class="wrong-link"
                                        style="color:#FF7F4D; text-decoration: underline; cursor: pointer;font-weight: 700">
                                    {{ props.row.count }}
                                </span>
                            </td>
                        </template>
                    </q-table>
                </div>
                <div v-if="$i18n.locale == 'en'">
                    <q-table

                            :data="WrongTypedata"
                            :columns="columnsEn"
                            separator="cell"
                            @row-click="rowClick"
                            flat
                            bordered
                            :rows-per-page-options="[20]"
                    >
                        <template v-slot:body-cell-protein="props">
                            <td  class="q-td text-right">
                                <span  class="wrong-link" style="color:#FF7F4D; text-decoration: underline; cursor: pointer;font-weight: 700">{{ props.row.count }}</span>
                            </td>
                        </template>
                    </q-table>
                </div>


            </div>
            <div class="page-section">
                <div class="title">{{$t('home.Wronganswerssummarybyknowledgepoint')}}</div>
                <div v-if="$i18n.locale == 'zh'">
                    <q-table
                            :data="pointTypedata"
                            :columns="pointColumns"
                            separator="cell"
                            flat
                            bordered
                            @row-click="rowClickpoint"
                            :rows-per-page-options="[200]"
                    >
                        <template v-slot:body-cell-protein="props">
                            <td  class="q-td text-right">
                                <span  class="wrong-link" style="color:#FF7F4D; text-decoration: underline; cursor: pointer;font-weight: 700">{{ props.row.count }}</span>
                            </td>
                        </template>
                    </q-table>
                </div>
                <div v-if="$i18n.locale == 'en'">
                    <q-table
                            :data="pointTypedata"
                            :columns="pointColumnsEn"
                            separator="cell"
                            flat
                            bordered
                            @row-click="rowClickpoint"
                            :rows-per-page-options="[200]"
                    >
                        <template v-slot:body-cell-protein="props">
                            <td  class="q-td text-right">
                                <span  class="wrong-link" style="color:#FF7F4D; text-decoration: underline; cursor: pointer;font-weight: 700">{{ props.row.count }}</span>
                            </td>
                        </template>
                    </q-table>
                </div>


            </div>
        </div>
        <router-view v-else></router-view>
    </div>
</template>

<script>
    export default {
        name: "WrongBook",
        data(){
            return{
                WrongTypedata:[],
                pointTypedata:[],
                totalWrong:0,
                data:[],
                columns: [
                    { name: 'proteins', label: '错题分类统计', align: 'left',field:'key_cn', },
                    { name: 'protein', label: '错题数', align: 'right',field:'count', },
                ],
                columnsEn: [
                    { name: 'proteins', label: 'Result By Category', align: 'left',field:'key_en', },
                    { name: 'protein', label: 'Number Of Wrong Answers', align: 'right',field:'count', },
                ],
                pointColumns: [
                    { name: 'proteins', label: '知识点', align: 'left',field:'key_cn', },
                    { name: 'protein', label: '错题数', align: 'right',field:'count', },
                ],
                pointColumnsEn: [
                    { name: 'proteins', label: 'Knowledge Point', align: 'left',field:'key_en', },
                    { name: 'protein', label: 'Number Of Wrong Answers', align: 'right',field:'count', },
                ],
            }
        },
        created() {
            this.getWrongBookList()
        },
        methods:{
            // 获取错题本列表
            getWrongBookList(){
                this.$http.post('wrong_question/index').then(res =>{
                    console.log(res)
                    this.totalWrong = res.data.total_count
                    this.WrongTypedata = res.data.info.classify
                    this.pointTypedata = res.data.info.knowledge
                })
            },
            rowClick(evt, row, index){
                this.$router.push({path:'/wrongQuestion',query:{type:'classify',id:this.WrongTypedata[index].classify_id}})
                console.log('index',evt, row, index)
            },
            rowClickpoint(evt, row, index){
                this.$router.push({path:'/wrongQuestion',query:{type:'knowledge',id:this.pointTypedata[index].knowledge_id}})
                console.log('index',evt, row, index)
            }
        }
    }
</script>

<style scoped lang="less">
    .total-wrong{
        font-size: 16px;
        font-weight: 600;
    }
    .wrong-link{
        cursor: pointer;
        color: #FF7F4D;
        text-decoration: underline;
    }
    .title{
        margin-bottom: 15px;
        margin-top: -5px;
        color: #FF7F4D;
        font-size: 16px;
        font-weight: 600;
    }
</style>